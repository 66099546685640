import React from 'react'
import styled from 'styled-components'

interface Props {
  category: string
}

export const CategoryTitle = ({ category }: Props) => {
  return <Wrapper>&#9733; {category}</Wrapper>
}

const Wrapper = styled.h2`
  margin: 10px 0 50px 0;
  color: ${({ theme }) => theme.gold};
`
