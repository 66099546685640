import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { MarkdownRemark } from '../interfaces/markdownRemark'

interface Props {
  post: MarkdownRemark
}

export const ArticleCard = ({ post }: Props) => {
  const title = post.frontmatter.title || post.fields.slug
  const coverImage = getImage(post.frontmatter.cover)

  return (
    <li key={post.fields.slug}>
      <article itemScope itemType="http://schema.org/Article">
        <Link to={post.fields.slug} itemProp="url">
          <div>
            <GatsbyCustomImg image={coverImage} alt={'article cover'} objectFit={'cover'} />
            <H3>
              <span itemProp="headline">{title}</span>
            </H3>
          </div>
        </Link>
        <Section>
          <div
            dangerouslySetInnerHTML={{
              __html: post.excerpt.slice(0, 80)+'...',
            }}
            itemProp="description"
          />
          <div>
            <p>{post.frontmatter.date}</p>{' '}
            <Link
              to={
                post.frontmatter.language === 'en'
                  ? `/en/category/${post.frontmatter.category}`
                  : `/category/${post.frontmatter.category}`
              }
            >
              &#9733; {post.frontmatter.category}
            </Link>
          </div>
        </Section>
      </article>
    </li>
  )
}

const H3 = styled.h3`
  font-weight: 600;
  font-family: ${({ theme }) => theme.font.serif};
  font-size: ${({ theme }) => theme.font.h3};
  margin-top: 12px;
`

const Section = styled.div`
  font-weight: 300;
  color: ${({ theme }) => theme.grey};

  div:last-child {
    display: flex;
    color: ${({ theme }) => theme.lightGrey};
    font-weight: 700;
    margin-top: 5px;
  }

  a {
    color: ${({ theme }) => theme.gold};
    margin-left: 20px;
  }
`

const GatsbyCustomImg = styled(GatsbyImage)<any>`
  height: 300px;
  transition: transform 0.5s ease-out;

  img {
    transition: opacity 0.25s linear, transform 0.5s ease-out !important;
  }

  img:hover {
    transform: scale(1.02);
  }

  :hover {
    transform: translateY(-2px);
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.medium}) {
    height: 230px;
  }
`
