import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

export const SearchedPostsList = ({ data }: any) => {
  if (!data.length) return <p>No article found.</p>

  return (
    <Wrapper>
      {data.map((a: any) => (
        <Link to={a.node.fields.slug} key={a.node.frontmatter.title}>
          <Card>
            <GatsbyImage alt={'cover'} image={getImage(a.node.frontmatter.cover) as any} />
            <div>
              <h3>{a.node.frontmatter.title}</h3>
              <p>{a.node.frontmatter.description}</p>
            </div>
          </Card>
        </Link>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 100px;
`

const Card = styled.div`
  display: flex;
  margin: 15px 0;
  align-items: center;

  div:first-child {
    min-width: 100px;
  }

  div:last-child {
    padding-left: 20px;

    h3 {
      font-weight: 600;
      font-family: ${({ theme }) => theme.font.serif};
      font-size: ${({ theme }) => theme.font.h3};
    }

    p {
      color: ${({ theme }) => theme.grey};
    }
  }
`
