import React from 'react'
import styled from 'styled-components'

import { ArticleCard } from './articleCard'
import { PageNav } from './pageNav'
import { Location, PageContext } from '../interfaces/gatsby'
import { MarkdownRemark } from '../interfaces/markdownRemark'
import { Adsense } from './googleAdsense'

interface Props {
  posts: [MarkdownRemark]
  pageContext: PageContext
  location: Location
}

type StyleProps = {
  left: boolean
}

export const PostList = ({ posts, pageContext, location }: Props) => {
  const listRenderer = () => {
    let left = true
    let list = []

    for (let i = 0; i < posts.length; i += 2) {
      if (i === 4)
        list.push(
          <ul key={'ad'} style={{ marginBottom: '60px' }}>
            <Adsense />
          </ul>
        )
      list.push(
        <List key={i} left={left}>
          <ArticleCard post={posts[i]} />
          {posts[i + 1] ? <ArticleCard post={posts[i + 1]} /> : null}
        </List>
      )

      left = !left
    }

    return list
  }

  return (
    <>
      {listRenderer()}
      <PageNav pageContext={pageContext} location={location} />
    </>
  )
}

const List = styled.ul<StyleProps>`
  display: grid;
  grid-column-gap: 50px;
  grid-template-columns: ${({ left }) =>
    left ? 'minmax(300px, 5.8fr) minmax(300px, 4.2fr)' : 'minmax(300px, 4.2fr) minmax(300px, 5.8fr)'};
  margin-bottom: 50px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.small}) {
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
  }
`
