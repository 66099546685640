import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { PageContext, Location } from '../interfaces/gatsby'

interface Props {
  pageContext: PageContext
  location: Location
}

export const PageNav = ({ pageContext, location }: Props) => {
  const { category, currentPage, numPages, hasPrevPage, hasNextPage, prevPagePath, nextPagePath } = pageContext

  const pageNumList = () => {
    const list = [currentPage]

    for (let i = 1; i <= 4; i++) {
      if (currentPage - i >= 0 && list.length < 5) list.unshift(currentPage - i)
      if (currentPage + i < numPages && list.length < 5) list.push(currentPage + i)
    }

    return list
  }

  const setLinkToNav = (n: number) => {
    const components: string[] = []

    if (location.pathname.split('/')[1] === 'en') components.push('en')
    if (category) components.push(...['category', category])
    if (n !== 0) components.push(...['page', String(n)])

    return '/' + components.join('/')
  }

  return (
    <Wrapper>
      {hasPrevPage ? <Link to={prevPagePath}>prev</Link> : <p />}
      {hasPrevPage || hasNextPage ? (
        <PageNumList>
          {pageNumList().map((n) => (
            <Link key={n} to={setLinkToNav(n)} className={n === currentPage ? 'current' : ''}>
              {n}
            </Link>
          ))}
        </PageNumList>
      ) : null}
      {hasNextPage ? <Link to={nextPagePath}>next</Link> : <p />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  > a {
    font-weight: 500;
    margin: 0 20px;
  }
`

const PageNumList = styled.div`
  display: flex;

  a {
    margin: 0 15px;
    color: ${({ theme }) => theme.lightGrey};
  }

  a.current {
    color: inherit;
    cursor: default;
  }
`
