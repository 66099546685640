import React, { useState } from 'react'
import styled from 'styled-components'

import { MiniBio } from '../miniBio'
import { Layout } from '../layout'
import Seo from '../seo'
import { Description } from '../description'
import { Center } from '../layout'
import { PostList } from '../postList'
import { CategoryTitle } from '../categoryTitle'
import { SearchedPostsList } from '../searchedPostsList'
import { Location, PageContext } from '../../interfaces/gatsby'
import { SiteMetadata } from '../../interfaces/siteMetadata'

interface Props {
  data: any
  location: Location
  siteMetadata: SiteMetadata
  pageContext: PageContext
}

export const Main = ({ data, location, siteMetadata, pageContext }: Props) => {
  const posts = data.allMarkdownRemark.edges.map((e: any) => e.node)
  const en = pageContext.language === 'en'
  const category = pageContext.category

  const [timer, setTimer] = useState<NodeJS.Timeout>()
  const [searchedArticles, setSearchedArticles] = useState([])
  const [searchInput, setSearchInput] = useState('')

  const searchArticles = (keyword: string) => {
    keyword = keyword.toLowerCase()

    const filteredPosts = data.archives.edges.filter(
      (e: any) =>
        e.node.frontmatter.title.toLowerCase().includes(keyword) ||
        e.node.frontmatter.description.toLowerCase().includes(keyword) ||
        e.node.frontmatter.tags.includes(keyword)
    )

    setSearchedArticles(filteredPosts)
  }

  const searchInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)

    timer && clearTimeout(timer)
    setTimer(
      setTimeout(() => {
        searchArticles(e.target.value)
      }, 250)
    )
  }

  if (posts.length === 0) {
    return (
      <Layout en={en} location={location} siteMetadata={siteMetadata}>
        <MiniBio en={en} siteMetadata={siteMetadata} />
        <p>No blog posts found.</p>
      </Layout>
    )
  }

  return (
    <Layout en={en} location={location} siteMetadata={siteMetadata}>
      <Seo lang={pageContext.language} />
      <Center>
        {category ? (
          <CategoryTitle category={category} />
        ) : (
          <>
            <Description en={en} siteMetadata={siteMetadata} />
            <MiniBioAndInput>
              <MiniBio en={en} siteMetadata={siteMetadata} />
              <div>
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 489.713 489.713"
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      d="M483.4,454.444l-121.3-121.4c28.7-35.2,46-80,46-128.9c0-112.5-91.5-204.1-204.1-204.1S0,91.644,0,204.144
		s91.5,204,204.1,204c48.8,0,93.7-17.3,128.9-46l121.3,121.3c8.3,8.3,20.9,8.3,29.2,0S491.8,462.744,483.4,454.444z M40.7,204.144
		c0-90.1,73.2-163.3,163.3-163.3s163.4,73.3,163.4,163.4s-73.3,163.4-163.4,163.4S40.7,294.244,40.7,204.144z"
                    />
                  </g>
                </svg>
                <input value={searchInput} placeholder={'Search articles'} onChange={searchInputChanged} />
              </div>
            </MiniBioAndInput>
          </>
        )}
        {searchInput ? (
          <SearchedPostsList data={searchedArticles} />
        ) : (
          <PostList posts={posts} pageContext={pageContext} location={location} />
        )}
      </Center>
    </Layout>
  )
}

const MiniBioAndInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: -45px;

  div:last-child {
    position: relative;

    svg {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 20px;
    }

    input {
      height: 40px;
      border-radius: 8px;
      padding-left: 40px;

      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    input::placeholder {
      color: rgba(0, 0, 0, 0.2);
    }

    input:focus {
      outline: none;
      border: 2px solid ${({ theme }) => theme.accentMagenta};
      box-shadow: 0 0 2px ${({ theme }) => theme.accentMagenta};
    }
  }

  @media screen and (max-width: 780px) {
    div:last-child {
      display: none;
    }
  }
`
