import React from 'react'
import styled from 'styled-components'
import { SiteMetadata } from '../interfaces/siteMetadata'

interface Props {
  en: boolean
  siteMetadata: SiteMetadata
}

interface StyleProps {
  en?: boolean
  theme: any
}

export const Description = ({ en, siteMetadata }: Props) => {
  return (
    <Wrapper>
      {en ? (
        <H2 en={en} dangerouslySetInnerHTML={{ __html: siteMetadata.headline.en }} />
      ) : (
        <H2 dangerouslySetInnerHTML={{ __html: siteMetadata.headline.ja }} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: ${({ theme }) => theme.description.verticalMargin} 0;
`

const H2 = styled.h2<StyleProps>`
  width: 70vw;
  max-width: 760px;
  font-size: ${({ theme, en }) => (en ? theme.font.h2 : theme.font.h2_ja)};
  line-height: initial;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.small}) {
    width: 80vw;
    font-size: 5vw;
  }
`
